import * as React from "react"
import Logo from "../images/logo.svg";
import Container from "./Container";

const Header = () => {
    return (
        <header className="text-white bg-slate-100">
            <Container>
                <Logo className="w-auto h-16" />
            </Container>
        </header>
    )
}

export default Header;