import * as React from "react"
import Layout from "../components/Layout"
import Container from "../components/Container"
import { Link } from "gatsby"

const IndexPage = () => {
    
    return (
        <Layout>
            <Container>
                <h1>Perspective Team Event 2023</h1>
                <p className="text-3xl text-gray-400">14 - 15 June 2023</p>
                <div className="mt-12">
                    <h2>Quick Links</h2>
                    <ul className="pl-4 list-disc">
                        <li key="allAttendees" className="hover:underline"><Link to="/attendees">View all attendees</Link></li>
                    </ul>
                </div>
            </Container>
        </Layout>
    )
}

export default IndexPage