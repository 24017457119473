import * as React from "react";
import Container from "./Container";

const Footer = () => {
    return (
        <footer className="text-white bg-[#002139] border-t-8 border-solid border-[#980321]">
            <Container>
                <p>© Copyright 2023</p>
            </Container>
        </footer>
    )
}

export default Footer;