import * as React from "react"

const Container = ({ children }) => {
    return (
        <div className="container px-8 py-16 mx-auto">
            { children }
        </div>
    )
}

export default Container